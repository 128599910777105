import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { CompareState } from '../../../../state/compare.state';
import { ProductService } from 'src/app/shared/services/product.service';

@Component({
  selector: 'app-header-compare',
  templateUrl: './compare.component.html',
  styleUrls: ['./compare.component.scss']
})
export class CompareComponent  {
  totalcount: number=0;
  @Select(CompareState.compareTotal) compareTotal$: Observable<number>;
 
  constructor(private services:ProductService){}
  
      ngOnInit(): void {
    //   this.services.totalcount$.subscribe(count => {
    //   this.totalcount = count;
     
    // });
  
  }
}
