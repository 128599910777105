<ng-template #deleteModal let-modal>
    <div class="modal-body">
        <i class="ri-delete-bin-line icon-box"></i>
        <h5 class="modal-title">Delete item</h5>
        <p>Are you sure you want to delete? </p>
        <div class="button-box">
            <app-button 
                [class]="'btn btn-theme-outline btn-md fw-bold'" 
                [spinner]="false"
                [id]="'delete_no_btn'"
                (click)="modal.dismiss('Cancel')">
                No
            </app-button>
            <app-button
                [class]="'btn theme-bg-color btn-md fw-bold text-light'"
                [id]="'delete_yes_btn'"
                (click)="delete(modal)">
                Yes
            </app-button>
        </div>
    </div>
</ng-template>
