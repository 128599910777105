import { Component, Input, ViewChild, SimpleChanges } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Product, ProductModel } from '../../../../../shared/interface/product.interface';
import { Option } from '../../../../../shared/interface/theme-option.interface';
import { ProductState } from '../../../../../shared/state/product.state';
import { DealsModalComponent } from '../../../widgets/modal/deals-modal/deals-modal.component';
import { GetDealProducts } from '../../../../../shared/action/product.action';
import { ProductService } from 'src/app/shared/services/product.service';

@Component({
  selector: 'app-deal',
  templateUrl: './deal.component.html',
  styleUrls: ['./deal.component.scss']
})
export class DealComponent {

  @Input() style: string = 'basic';
  @Input() data: any;

  @ViewChild("dealsModal") DealsModal: DealsModalComponent;

  @Select(ProductState.dealProducts) dealProducts$: Observable<ProductModel>;

  public dealProducts: any[] = [];
  public ids: number[];

  constructor(private store: Store, private productService : ProductService) {
  }

 

  ngOnInit(){
    // if(Array.isArray(this.ids)){
    //   this.store.dispatch(new GetDealProducts({ids: this.ids.join()})).subscribe({
    //     next: (val) => {
    //      this.dealProducts = val?.product?.dealProducts;
    //     }
    //   });
    // }
   
  }
}
