import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { AccountUser } from "../interface/account.interface";
import { ActivatedRoute, Router } from "@angular/router";
import { json } from "express";

@Injectable({
  providedIn: "root",
})
export class AccountService {

  private isAuthenticatedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private modalSubmit: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  

  constructor(private http: HttpClient,private route: ActivatedRoute,private router: Router) {
    this.isAuthenticatedSubject.next(this.loggedIn());
   }

  
  userRegister(data:any){
    return this.http.post(`${environment.apiUrl}Customer/addUser`,data);
  }

  userlogin(data:any){
    return this.http.post(`${environment.apiUrl}Customer/loginapi/`,data);
  }

  setToken(token:any){
    localStorage.setItem('token',token)
  }

  setUserData(data:any){
    localStorage.setItem('user',JSON.stringify(data));
    this.isAuthenticatedSubject.next(true);
  }

  getToken(){
    return localStorage.getItem('token')
  }

  loggedIn(){
    return !!localStorage.getItem("user")
  }
  
  
  getAuthenticate(){
    return this.isAuthenticatedSubject.asObservable()
  }


  getUserDetails(): Observable<AccountUser> {
    return this.http.get<AccountUser>(`${environment.URL}/account.json`);
  }

  profileUpdate(id:any,data:any){
    return this.http.put(`${environment.apiUrl}Customer/updateUser/${id}`,data);
  }

  getUserData(){
    const details : any = localStorage.getItem('user')
    return JSON.parse(details);
  }

  getUser(id:any){
    return this.http.get(`${environment.apiUrl}Customer/searchUserbyId/${id}`);
  }

  delUserData(){
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    this.isAuthenticatedSubject.next(false);

  }

  changePassword(data:any){
    return this.http.post(`${environment.apiUrl}Customer/change-password/`,data);
  }

  getOrderedList(userid:any,sh_id:any){
    return this.http.get(`${environment.apiUrl}Order/retrieveOrderbyUserid/${userid}/${sh_id}`);
  }

  addNewsletter(data:any){
    return this.http.post(`${environment.apiUrl}Product/CreateNewsEnquiry/`,data);
  }

  setUserDetails(data:any){
    this.modalSubmit.next(data);
  }

  getUserDetail(){
    return this.modalSubmit.asObservable()
  }

  forgotPassword(data:any){
    return this.http.post(`${environment.apiUrl}Customer/forgotpass/`,data);

  }


}
