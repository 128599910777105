import { Component, Input } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Product, ProductModel } from '../../../../shared/interface/product.interface';
import { ProductState } from '../../../../shared/state/product.state';
import { environment } from 'src/environments/environment';
// import { Ng2ImgMaxService , ImgMaxSizeService} from 'ng2-img-max';

@Component({
  selector: 'app-image-link',
  templateUrl: './image-link.component.html',
  styleUrls: ['./image-link.component.scss']
})
export class ImageLinkComponent {

  @Select(ProductState.product) product$: Observable<ProductModel>;

  @Input() image: any;
  @Input() link: string;
  @Input() bgImage: boolean;
  @Input() class: string;
  imageUrl : any = '';
  @Input() bannerName: any;
  @Input() from_bottom : any


  constructor(){
    this.imageUrl = environment?.imageUrl

  }

  ngOnInit(){
   
  }


  getProductSlug(id: number, products: Product[]){
    let product = products.find(product => product.id === id);
    return product ? product.slug : null;
  }

}
