// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


  // export const environment = {
  //   production: false,
  //   baseURL   : 'http://localhost:4200/',
  //   URL       : 'http://localhost:4200/assets/data',
  //   apiUrl    :'http://65.109.99.178:9011/',
  //   sh_id     : 4,
   
  //   imageUrl  : 'http://65.109.99.178:9011'
  // }
  export const environment = {
    production: false,
    baseURL   : 'https://ecom.senscript.ai/',
    URL       : 'https://ecom.senscript.ai/assets/data',
    apiUrl    : 'https://ecombackend.senscript.ai/',
    sh_id     :  4,
  
    imageUrl  : 'https://ecombackend.senscript.ai'
  };


/*
* For easier debugging in development mode, you can import the following file
* to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
*
* This import should be commented out in production mode because it will have a negative impact
* on performance if an error is thrown.
*/
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
