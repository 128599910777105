import { Component, TemplateRef, ViewChild, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Product } from '../../../../../shared/interface/product.interface';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { environment } from 'src/environments/environment';
import { AccountService } from 'src/app/shared/services/account.service';
import { ProductService } from 'src/app/shared/services/product.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-review-modal',
  templateUrl: './review-modal.component.html',
  styleUrls: ['./review-modal.component.scss'],

})
export class ReviewModalComponent {

  @ViewChild("reviewModal", { static: false }) ReviewModal: TemplateRef<string>;
  // Define a class member to store the modal reference
  private modalRef: NgbModalRef | undefined; // Add this member to your class
  public closeResult: string;
  public modalOpen: boolean = false;
  public product: any;
  public currentRate: number = 0;
  public review = new FormControl('', [Validators.required])
  public form: FormGroup;
  public type: string;
  apiUrl = environment.imageUrl;
  orderId: any = ''


  constructor( private modalService: NgbModal, 
    @Inject(PLATFORM_ID) private platformId: Object,
    private store: Store, private AccountService : AccountService, private ProductService : ProductService, private toastr: ToastrService, ){
    this.form = new FormGroup({
      rating: new FormControl('', [Validators.required]),
      description: new FormControl('')
    })
  }

  async openModal(product: Product, type: string,orderId: any) {
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalOpen = true;
      this.type = type;
      this.product = product;
      this.orderId = orderId;
      type && type === 'edit' && this.form.patchValue({rating: product.user_review.rating, description: product.user_review.description})

      this.modalService.open(this.ReviewModal, {
        ariaLabelledBy: 'profile-Modal',
        centered: true,
        windowClass: 'theme-modal'
      }).result.then((result) => {
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  submit(modal:any){
    this.form.markAllAsTouched();
    if(this.form.valid){
      let data = {
        orderid : this.orderId,
        userid : this.AccountService?.getUserData()?.id,
        productid: this.product?.id,
        rating: this.form.get('rating')?.value,
        comment: this.form.get('description')?.value,
        subject : ''
      }
      this.ProductService.addRatings(data).subscribe((res:any)=>{
        this.form.reset();
        if(res.status === 'success'){
          this.toastr.success(res?.message,'success');
        }else{
          this.toastr.error(res?.message,'error');
          modal.close()
        }
      })
     
    }
  }
}
