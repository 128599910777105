import { Component, TemplateRef, ViewChild, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { UpdateUserPassword } from '../../../../action/account.action';
import { CustomValidators } from '../../../../validator/password-match';
import { AccountService } from 'src/app/shared/services/account.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';


@Component({
  selector: 'app-change-password-modal',
  templateUrl: './change-password-modal.component.html',
  styleUrls: ['./change-password-modal.component.scss']
})
export class ChangePasswordModalComponent {

  public form: FormGroup;
  public closeResult: string;

  public modalOpen: boolean = false;

  @ViewChild("passwordModal", { static: false }) PasswordModal: TemplateRef<string>;
  
  constructor(private modalService: NgbModal,
    @Inject(PLATFORM_ID) private platformId: Object,
    private store: Store,
    private formBuilder: FormBuilder,private accountService : AccountService,private toastr: ToastrService,  private router: Router) {
      this.form = this.formBuilder.group({
        old_password: new FormControl('', [Validators.required]),
        password: new FormControl('', [Validators.required]),
        password_confirmation: new FormControl('', [Validators.required])
      },{validator : CustomValidators.MatchValidator('password', 'password_confirmation')})
  }

  async openModal() {
    if (isPlatformBrowser(this.platformId)) { 
      this.form?.reset()// For SSR 
      this.modalOpen = true;
      this.modalService.open(this.PasswordModal, {
        ariaLabelledBy: 'password-Modal',
        centered: true,
        windowClass: 'theme-modal'
      }).result.then((result) => {
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  private getDismissReason(reason: ModalDismissReasons): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  get passwordMatchError() {
    return (
      this.form?.getError('mismatch') &&
      this.form?.get('password_confirmation')?.touched
    );
  }

  submit(){
    this.form.markAllAsTouched();
    if(this.form.valid) {
      var dataArr = {
        'email'  : this.accountService.getUserData()?.email,
        'old_password' : this.form?.value?.old_password,
        'password'  : this.form?.value?.password_confirmation,
      }
      this.accountService.changePassword(dataArr).subscribe((res:any)=>{
        if(res){
          this.toastr.success(res?.success,'success');
          this.modalService.dismissAll('Cross click');
        }
      },(error:any)=>{
        this.toastr.error(error?.error.error,'error');
      })
    }
    }
  

  ngOnDestroy() {
    if(this.modalOpen) {
      this.modalService.dismissAll();
    }
  }

}
