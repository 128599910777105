<footer class="section-t-space" [ngClass]="footer.footer_class">
    <div class="container-fluid-lg">
        <div class="main-footer section-b-space">
            <div class="row g-md-4 g-3">
                <div class="col-xl-3 col-lg-3 col-sm-6 col-md-3"  (click)="toggle('categories')">
                    <!-- <div class="footer-logo">
                        <app-footer-logo [data]="data!" [footer]="footer"></app-footer-logo>
                        <app-footer-about [data]="data"></app-footer-about>
                    </div> -->
                    <div class="footer-title" [class.show]="active['categories']">
                        <h4>Categories</h4>
                    </div>
                    <div class="footer-contain">
                        <app-footer-categories [data]="data"></app-footer-categories>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6"  (click)="toggle('useful_link')">
                    <div class="footer-title" [class.show]="active['useful_link']">
                        <h4>Useful Links</h4>
                    </div>
                    <div class="footer-contain">
                        <app-footer-links [links]="linkdata || []"></app-footer-links>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-sm-6 col-md-3" >
                    <div class="footer-title" [class.show]="active['help_center']">
                        <h4>Help Center</h4>
                    </div>
                    <div class="footer-contain">
                        <app-footer-links [links]="helpCenter || []"></app-footer-links>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-sm-6 col-md-3"
                >
                 <div class="footer-title contact-title">
                     <h4>Contact Us</h4>
                 </div>
                 <app-footer-contact [data]="data"></app-footer-contact>
             </div>
                <div class="col-xl-4 col-lg-4 col-sm-6 col-md-4" >
                    <div class="footer-title contact-title">
                        <h4>Registered Office Address</h4>
                    </div><br>
                    <p>20 Cooper Square,20 Cooper Square, New York, NY 10003, USA</p>
                </div>
               
            </div>
        </div>
        <div class="sub-footer section-small-space">
            <!-- <div class="reserve" *ngIf="data?.footer?.footer_copyright">
                <app-footer-copyright [data]="data"></app-footer-copyright>
            </div> -->
            <div class="payment">
                <app-footer-payment-options></app-footer-payment-options>
            </div>
            <app-footer-social-links [data]="data" *ngIf="data?.footer?.social_media_enable"></app-footer-social-links>
        </div>
    </div>
</footer>
