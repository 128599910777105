import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class AuthService {

  public redirectUrl: string | undefined;

  // Auth Function Here
  scrollToTop() {
    // Scroll to the top of the page
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth' // Optional: use 'auto' for instant scrolling
    });
  }
  
}
