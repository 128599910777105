import { Component, TemplateRef, ViewChild, PLATFORM_ID, Inject, Output, EventEmitter } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { map, Observable } from 'rxjs';
import { Select2Data, Select2UpdateEvent } from 'ng-select2-component';
import { CountryState } from '../../../../state/country.state';
import { StateState } from '../../../../state/state.state';
import { UserAddress } from '../../../../interface/user.interface';
import * as data from '../../../../data/country-code';
import { ProductService } from 'src/app/shared/services/product.service';
import { environment } from 'src/environments/environment';
import { AccountService } from 'src/app/shared/services/account.service';
import { ToastrService } from 'ngx-toastr';
import { CountryService } from 'src/app/shared/services/country.service';


@Component({
  selector: 'address-modal',
  templateUrl: './address-modal.component.html',
  styleUrls: ['./address-modal.component.scss']
})
export class AddressModalComponent {

  public form: FormGroup;
  public closeResult: string;
  public modalOpen: boolean = false;
  id : any ;

  public states$: Observable<Select2Data>;
  address: boolean = false ;
  public codes = data.countryCodes;
  userId : any = '';
  submitted = false;
  indianPincodePattern = /^[1-9][0-9]{5}$/;
  usZipcodePattern = /^\d{5}(-\d{4})?$/;
  addressDetail :  any = '';
  label : any = ''

  @ViewChild("addressModal", { static: false }) AddressModal: TemplateRef<string>;
  @Select(CountryState.countries) countries$: Observable<Select2Data>;
  @Output()updateItem: EventEmitter<any> = new EventEmitter();
  countryArr : any = [];
  stateArr : any = [];
  countries : any = []
  constructor(private modalService: NgbModal,
    @Inject(PLATFORM_ID) private platformId: Object,
    private store: Store,
    private formBuilder: FormBuilder,private ProductService : ProductService, private AccountService : AccountService,private toastr: ToastrService, private CountryService : CountryService, ) {
      this.CountryService.getCountries().subscribe((res:any)=>{
        this.countries = res
        res.forEach((item:any)=>{
           this.countryArr.push({
            label : item?.name,
            id:item?.id
           })
        })
        
      })
     this.setForm();
     this.userId = this.AccountService.getUserData()?.id;
    }

  setForm(){
    this.form = this.formBuilder.group({
      fullname: new FormControl('', [Validators.required,Validators.pattern(/^[a-zA-Z\s]+$/)]),
      street: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required,Validators.email]),
      state_id: new FormControl(null, [Validators.required]),
      country_id: new FormControl(null, [Validators.required]),
      city: new FormControl('', [Validators.required,Validators.pattern(/^[a-zA-Z\s]+$/)]),
      pincode: new FormControl('', [Validators.required,this.multiPatternValidator()]),
      // country_code: new FormControl('91'),
      phone: new FormControl('', [Validators.required, Validators.pattern(/^\d{10}$|^\d{12}$/),]),
      note: new FormControl('')
    })
  }

  // Custom validator function for multiple patterns
  multiPatternValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value) {
        return null;
      }
      // Check against multiple patterns
      const valid = this.indianPincodePattern.test(value) || this.usZipcodePattern.test(value);
      return valid ? null : { 'pincodeInvalid': true };
    };
  }

  countryChange(data: any,type:any) {
   this.stateArr = [];
   this.label = type == 'from_update' ? data : data?.target?.value;
    this.countries.forEach((item:any)=>{
    if(item?.name == this.label){
      item?.state.forEach((stateitem:any)=>{
        this.stateArr.push({
          label : stateitem?.name,
          id:stateitem?.id
         })

      })

    }

  })
  }

  async openModal(value?: any) {
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalOpen = true;
      this.id = value;
      this.patchForm(value);
      this.modalService.open(this.AddressModal, {
        ariaLabelledBy: 'address-add-Modal',
        centered: true,
        windowClass: 'theme-modal modal-lg'
      }).result.then((result) => {
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }


  private getDismissReason(reason: ModalDismissReasons): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  patchForm(value?: any) {
    if(value) {
      this.ProductService.getAddressDetail(value).subscribe((res:any)=>{
        if(res?.data){
          this.address = true;
          this.countryChange(res?.data?.country,'from_update');
          this.form.patchValue({
            fullname : res?.data?.firstname + ' ' + (res?.data?.lastname != null? res?.data?.lastname : ''),
            street : res?.data?.address,
            email : res?.data?.email,
            state_id :res?.data?.state,
            country_id : res?.data?.country,
            city : res?.data?.city,
            pincode : res?.data?.zipcode,
            phone : res?.data?.phone,
            note : res?.data?.note
          })
        }
      })
    } else {
      this.address = false;
      this.form.reset();
      this.form?.get('country_code')?.setValue('91');
    }
  }

  submit(modal:any){
    this.form.markAllAsTouched();
    console.log(this.form?.value?.country_id,this.form?.value?.state_id,'state_id')
    this.submitted = true;
    if(this.form.valid) {
      const full_name = this.form.value.fullname.split(' ');
      const firstname = full_name[0];
      const lastname = full_name[1];
      const data = {
        firstname : firstname,
        lastname : lastname,
        country : this.form?.value?.country_id,
        city : this.form?.value?.city,
        state : this.form?.value?.state_id,
        zipcode : this.form?.value?.pincode,
        email : this.form?.value?.email,
        phone : this.form?.value?.phone,
        address : this.form?.value?.street,
        note : this.form?.value?.note,
        shop : environment?.sh_id,
        customer : this.userId
      }
      if(!this.address){
        this.ProductService.addBillAddress(environment?.sh_id,data).subscribe((res:any)=>{
          if(res?.data){
            this.ProductService.getBillAddress(true)
            this.toastr.success('Address added successfully','success');
            modal.close();
        }
      })
      }else{
        this.ProductService.updateAddress(this.id,data).subscribe((res:any)=>{
          this.updateItem.emit(true);
          modal.close();
          
        })
      }
     
    }
  }

  ngOnDestroy() {
    if(this.modalOpen) {
      this.modalService.dismissAll();
    }
  }

}
