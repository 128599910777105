<ng-template #reviewModal let-modal>
  <div class="modal-header">
      <h3 class="fw-semibold">Write a review</h3>
      <app-button
          [class]="'btn btn-close'"
          [spinner]="false"
          [id]="'profile_modal_close_btn'"
          (click)="modal.dismiss('Cross click')">
          <i class="ri-close-line"></i>
      </app-button>
  </div>

  <div class="modal-body">
    <form class="product-review-form" [formGroup]="form">
      <div class="product-wrapper">
        <div class="product-image">
          <img [src]="product?.product_thumbnails?.original_url ?
            apiUrl+ product?.product_thumbnails?.original_url :
                    'assets/images/product.png'" class="img-fluid" [alt]="product?.productname">
        </div>
        <div class="product-content">
          <h5 class="name">{{ product.productname }}</h5>
          <div class="product-review-rating">
            <label>rating</label>
            <div class="product-rating">
              <ngb-rating [rate]="product.rating_count"></ngb-rating>
              <h6 class="rating-number" *ngIf="product.rating_count">( {{ product.rating_count.toFixed(2) || 0 }})</h6>
            </div>
          </div>
        </div>
      </div>
      <div class="review-box">
        <div class="product-review-rating">
          <label>rating</label>
          <div class="product-rating">
            <ngb-rating [(rate)]="currentRate" formControlName="rating" [readonly]="false"></ngb-rating>
          </div>
          <div class="invalid-feedback" *ngIf="form?.controls?.['rating']?.touched && form.controls['rating']?.errors?.['required']">
            rating is required
        </div>
        </div>
      </div>

      <div class="review-box">
        <label class="form-label" for="content">review content</label>
        <textarea placeholder="write something" class="form-control" formControlName="description" rows="3"></textarea>
      </div>
    </form>

    <div class="modal-footer">
      <app-button [class]="'btn btn-theme-outline btn-md fw-bold'" [type]="'button'" [spinner]="false"
        [id]="'cancel_profile_btn'" (click)="modal.dismiss('Cancel')">cancel</app-button>
      <app-button [class]="'btn theme-bg-color btn-md fw-bold text-light'" [id]="'submit_profile_btn'" (click)="submit(modal);">
        submit
      </app-button>
    </div>
  </div>
</ng-template>
