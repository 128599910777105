<div class="offer-product">
    <a [routerLink]="['/product/', product?.productname,product?.id,product?.skid]" class="offer-image">
        <img src="{{ product?.product_thumbnails?.original_url ?
            apiUrl+ product?.product_thumbnails?.original_url :
            'assets/images/product.png' }}" alt="product">
    </a>
    <div class="offer-detail">
        <div>
            <a [routerLink]="['/product/', product?.productname,product?.id,product?.skid]" class="text-title">
                <h6 class="name">{{ product?.productname }}</h6>
            </a>
            <span *ngIf="product?.unit">{{ product?.unit }}</span>
            <div class="vertical-price">
                <h5 class="price theme-color">{{ product?.salesrate | currencySymbol }}</h5>
                <del class="text-content" *ngIf="product?.discount">{{ product?.price | currencySymbol }}</del>
            </div>
        </div>
    </div>
</div>
