<!-- Wishlist Basic -->
<a  (click)="navigateLink()"  class="btn p-0 position-relative header-wishlist" *ngIf="wishlistFlag">
    <i class="ri-heart-line"></i>
    <span class="position-absolute top-0 start-100 translate-middle badge">
       {{wishlistCount}} 
    </span>
</a>








