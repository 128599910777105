import { Component, Input } from '@angular/core';
import { Option } from '../../../../shared/interface/theme-option.interface';
import { Footer } from '../../../../shared/interface/theme.interface';

@Component({
  selector: 'app-basic-footer',
  templateUrl: './basic-footer.component.html',
  styleUrls: ['./basic-footer.component.scss']
})
export class BasicFooterComponent {

  @Input() data: Option | null;
  @Input() footer: Footer;

  public active: { [key: string]: any }  = {
    categories: false,
    useful_link: false,
    help_center: false,
  };

  linkdata =   [
    {
      "label": "Home",
      "link": ""
    },
    {
      "label": "Collections",
      "link": "collections"
    },
    {
      "label": "About Us",
      "link": "about-us"
    },
   
    // {
    //   "label": "Search",
    //   "link": "search"
    // }
  ]

  helpCenter =  [
    {
      "label": "My Account",
      "link": "account/dashboard"
    },
    {
      "label": "My Orders",
      "link": "account/order"
    },
    {
      "label": "Wishlist",
      "link": "wishlist"
    },
   
    {
      "label": "Contact Us",
      "link": "contact-us"
    }
  ]

  toggle(value: string){
    this.active[value] = !this.active[value];
  }
}
