<ng-template #profileModal let-modal>
    <div class="modal-header">
        <h3 class="fw-semibold">Edit profile</h3>
        <app-button
            [class]="'btn btn-close'"
            [spinner]="false"
            [id]="'profile_modal_close_btn'"
            (click)="modal.dismiss('Cross click')">
            <i class="ri-close-line"></i>
        </app-button>
    </div>
    <form [formGroup]="form" (ngSubmit)="submit()" *ngIf="!flicker">
        <div class="modal-body address-form">
            
            <div class="row g-4">
                <div class="col-xxl-12">
                    <div class="">
                        <label class="form-label" for="name">First Name</label>
                        <input class="form-control" id="name" type="text" placeholder="enter name" formControlName="name" />
                        <div class="invalid-feedback" *ngIf="form?.controls?.['name']?.touched && form.controls['name']?.errors?.['required'] ">
                            name is required
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="">
                        <label class="form-label" for="email">Email address</label>
                        <input class="form-control" id="email" type="text" placeholder="enter email address" formControlName="email" >
                        <div class="invalid-feedback" *ngIf="form?.controls?.['email']?.touched && form.controls['email']?.errors?.['required']">
                            email address is required
                        </div>
                        <div class="invalid-feedback" *ngIf="form.controls['email'].touched && form.controls['email'].errors?.['email']">
                            please enter valid email
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="">
                        <label class="form-label" for="city">City</label>
                        <input class="form-control" id="city" type="text" placeholder="enter city" formControlName="city">
                        <div class="invalid-feedback" *ngIf="form?.controls?.['city']?.touched && form.controls['city']?.errors?.['required']">
                            city is required
                        </div>
                        <!-- <div class="invalid-feedback" *ngIf="form.controls['city'].touched && form.controls['city'].errors?.['city']">
                            {{ 'please_enter_valid_email' | translate }}
                        </div> -->
                    </div>
                </div>
                <div class="col-12">
                    <div class="">
                        <label class="form-label" for="address1">Phone number</label>
                        <!-- <select2
                            class="custom-select intl-tel-input"
                            [templates]="template"
                            [data]="codes"
                            formControlName="country_code">
                            <ng-template #template let-data="data">
                                <div class="country">
                                    <div class="flag-box">
                                        <div class="iti-flag {{data?.class}}"></div>
                                    </div>
                                    <span class="dial-code">{{data.code}}</span>
                                </div>
                            </ng-template>
                        </select2> -->
                        <input type="number" class="form-control intl-input-padding" formControlName="phone" placeholder="enter_phone" name="phone">
                        <div class="invalid-feedback" *ngIf="form.controls['phone'].touched && form.controls['phone'].errors?.['required']">
                            phone number is required
                        </div>
                        <div class="invalid-feedback" *ngIf="form.controls['phone'].touched && form.controls['phone'].errors?.['pattern']">
                            phone number is invalid
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <app-button [class]="'btn btn-theme-outline btn-md fw-bold'" [type]="'button'" [spinner]="false"
                    [id]="'cancel_profile_btn'" (click)="modal.dismiss('Cancel')">
                    cancel
                </app-button>
                <app-button [class]="'btn theme-bg-color btn-md fw-bold text-light'" [id]="'submit_profile_btn'">
                    submit
                </app-button>
            </div>
        </div>
    </form>
</ng-template>
