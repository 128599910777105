
<ul class="navbar-nav">

  <div *ngTemplateOutlet="recursiveMenu; context: { $implicit: menu }"></div>

  <ng-template #recursiveMenu let-menus>

    <ng-container *ngFor="let menu of menus">

      <li

        [ngClass]="[menu.type == 'sub' && menu.children ? 'nav-item dropdown' : 'nav-item', menu.megaMenu ? 'dropdown-mega' : '']"

        [class.new-nav-item]="menu.badge"

        [class.active]="menu.active">

        <!-- Your link templates here -->

        <a class="nav-link dropdown-toggle" [routerLink]="menu.path" (click)="onclicks(menu)" [class.show]="menu.active"

          *ngIf="menu.type === 'link'">

          {{ menu.title | translate }}

          <label class="menu-label {{ menu.labelClass }}" *ngIf="menu.label">{{ menu.label | translate }}</label>

        </a>

        <!-- Mega menu templates here -->

        <div class="dropdown-menu dropdown-menu-2" [class.show]="menu.active"

          *ngIf="menu.megaMenu && menu.megaMenuType === 'link'">

          <div class="row">

            <!-- Your mega menu content here -->

          </div>

        </div>

        <!-- Submenu template -->

        <ul class="dropdown-menu" *ngIf="menu.children && !menu.megaMenu" [class.show]="menu.active">

          <ng-container *ngTemplateOutlet="recursiveMenu; context: { $implicit: menu.children }"></ng-container>

        </ul>

      </li>

    </ng-container>

  </ng-template>

</ul>

 