import { Component } from '@angular/core';

@Component({
  selector: 'app-no-product-purchase',
  templateUrl: './no-product-purchase.component.html',
  styleUrl: './no-product-purchase.component.scss'
})
export class NoProductPurchaseComponent {

}
