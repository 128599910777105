import { Menu } from "../interface/menu.interface";

export const menu: Menu[] = [
  {
    id: 1,
    title: 'Home',
    type: 'link',
    megaMenu: true,
    megaMenuType: 'image',
    active: false,
    path : ''
   
  },
  {
    id: 2,
    title: 'Collections',
    type: 'link',
    megaMenu: true,
    megaMenuType: 'link',
    path: 'collections',
    active: false,
    slider: 'product',
  //   children: [
  //     {
  //       children: [
  //         {
  //           title: 'collection_layouts',
  //           type: 'sub',
  //         },
  //         {
  //           title: 'collection_left_sidebar',
  //           path: 'collections',
  //           params: { layout: 'collection_left_sidebar' },
  //           type: 'link',
  //           label: 'hot',
  //           labelClass: 'warning-label',
  //         },
  //         {
  //           title: 'collection_right_sidebar',
  //           path: 'collections',
  //           params: { layout: 'collection_right_sidebar' },
  //           type: 'link',
  //         },
  //         {
  //           title: 'collection_no_sidebar',
  //           path: 'collections',
  //           params: { layout: 'collection_no_sidebar' },
  //           type: 'link',
  //         },
  //         {
  //           title: 'collection_3_grid',
  //           path: 'collections',
  //           params: { layout: 'collection_3_grid' },
  //           type: 'link',
  //         },
  //         {
  //           title: 'collection_4_grid',
  //           path: 'collections',
  //           params: { layout: 'collection_4_grid' },
  //           type: 'link',
  //           label: 'new',
  //         },
  //         {
  //           title: 'collection_5_grid',
  //           path: 'collections',
  //           type: 'link',
  //           params: { layout: 'collection_5_grid' },
  //         },
  //         {
  //           title: 'Collection List View',
  //           path: 'collections',
  //           params: { layout: 'collection_list_view' },
  //           type: 'link',
  //         },
  //       ],
  //     },
  //     {
  //       children: [
  //         {
  //           title: 'collection_layouts',
  //           type: 'sub',
  //         },
  //         {
  //           title: 'category_slider',
  //           path: 'collections',
  //           params: { layout: 'collection_category_slider' },
  //           type: 'link',
  //         },
  //         {
  //           title: 'category_sidebar',
  //           path: 'collections',
  //           params: { layout: 'collection_category_sidebar' },
  //           type: 'link',
  //           label: 'new',
  //         },
  //         {
  //           title: 'category_banner',
  //           path: 'collections',
  //           params: { layout: 'collection_banner' },
  //           type: 'link',
  //         },
  //         {
  //           title: 'offcanvas_Filter',
  //           path: 'collections',
  //           params: { layout: 'collection_offcanvas_filter' },
  //           type: 'link',
  //         },
  //       ],
  //     },
  //   ],
  },
  {
    id: 3,
    title: 'About Us',
    type: 'link',
    megaMenu: true,
    megaMenuType: 'link',
    path: 'about-us',
    active: false,
    
    // slider: 'product',
  },
  {
    id: 4,
    title: 'Contact Us',
    type: 'link',
    megaMenu: true,
    megaMenuType: 'link',
    path: 'contact-us',
    active: false,
    slider: 'product',
  }
 
];
