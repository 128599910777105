<div class="footer-logo-contain">
    <p *ngIf="data?.footer?.footer_about">{{ data?.footer?.footer_about }}</p>
    <ul class="address">
        <li *ngIf="contactDetail?.address">
            <i class="ri-home-line"></i>
            <a href="https://www.google.com/maps" target="_blank">{{ contactDetail?.address }}</a>
        </li>
        <li *ngIf="contactDetail?.email1">
            <i class="ri-mail-line"></i>
            <a href="mailto:{{ contactDetail?.email1 }}">{{ contactDetail?.email1 }}</a>
        </li>
    </ul>
</div>
