import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-box',
  templateUrl: './product-box.component.html',
  styleUrls: ['./product-box.component.scss']
})
export class ProductBoxComponent implements OnInit {
 
  
  @Input() product: any;
  @Input() style: string  = 'horizontal';
  @Input() class: string;
  @Input() close: boolean;
  @Input() wishlistIcon : boolean

  ngOnInit(): void {
  }

  

}
