import { Component, TemplateRef, ViewChild, PLATFORM_ID, Inject,OnInit } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AccountUser } from "../../../../interface/account.interface";
import { AccountState } from '../../../../state/account.state';
import { UpdateUserProfile } from '../../../../action/account.action';
import * as data from '../../../../data/country-code';
import { AccountService } from 'src/app/shared/services/account.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';


@Component({
  selector: 'app-edit-profile-modal',
  templateUrl: './edit-profile-modal.component.html',
  styleUrls: ['./edit-profile-modal.component.scss']
})
export class EditProfileModalComponent  {

  @Select(AccountState.user) user$: Observable<AccountUser>;

  public form: FormGroup;
  public closeResult: string;

  public modalOpen: boolean = false;
  public flicker: boolean = false;
  public codes = data.countryCodes;
  user : any;
  submitted : any = false;
 

  @ViewChild("profileModal", { static: false }) ProfileModal: TemplateRef<string>;
  
  constructor(private modalService: NgbModal,
    private store: Store, @Inject(PLATFORM_ID) private platformId: Object,
    private formBuilder: FormBuilder, private AccountService : AccountService, private ToastrService : ToastrService, private router : Router) {
    this.getUser()
  }

  getUser(){
    this.user = this.AccountService.getUserData()
    this.AccountService.getUser(this.user?.id).subscribe((res:any)=>{
      if(res && res.length > 0){
        this.flicker = true;
        const userData = res[0]
        this.form = this.formBuilder.group({
          name: new FormControl(userData.first_name, [Validators.required]),
          city: new FormControl(userData.city, [Validators.required]),
          email: new FormControl(userData.email, [Validators.required, Validators.email]),
          phone: new FormControl(userData.phone, [Validators.required, Validators.pattern(/^[0-9]*$/)]),
        });
        this.form?.controls['email'].disable(); 
        this.flicker = false;

      }

    })
     
  }

  async openModal() {
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalOpen = true;
      this.getUser()
      this.modalService.open(this.ProfileModal, {
        ariaLabelledBy: 'profile-Modal',
        centered: true,
        windowClass: 'theme-modal'
      }).result.then((result) => {
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  private getDismissReason(reason: ModalDismissReasons): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  submit(){
    this.form.markAllAsTouched();
    this.submitted = true;
    if(this.form.valid) {
      var dataArr = {
        'name'   : this.form?.value?.name,
        'city'   : this.form?.value?.city,
        'email'  : this.form?.value?.email,
        'mobile' : this.form?.value?.phone
      }
      this.AccountService?.profileUpdate(this.user?.id,dataArr).subscribe((res:any)=>{
        if(res){
          this.ToastrService.success('Updated successfully','success');
          this.modalService.dismissAll('Cross click');
          this.AccountService.setUserDetails(true);
        }
      },(error:any)=>{

      })
    }
  }

 

  ngOnDestroy() {
    if(this.modalOpen) {
      this.modalService.dismissAll();
    }
  }

}
